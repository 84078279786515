import * as dayjs from 'dayjs';
import {PriceGroup} from "./price-group";
import {Product} from "./product";

export class Price {

    id: string;
    product_id: string;
    price_group_id: string;
    price: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product: Product;
    price_group: PriceGroup;

    constructor() {
    }

    static fromJSON(json: PriceJSON | string): Price {
        if (typeof json === 'string') {
            return JSON.parse(json, Price.reviver);
        } else {
            let price = Object.create(Price.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product: null,
                price_group: null
            }

            Object.keys(json).forEach((key, index) => {
                if (key === 'product' && json[key]) {
                    extra.product = Product.fromJSON(json[key]);
                }

                if (key === 'price_group' && json[key]) {
                    extra.price_group = PriceGroup.fromJSON(json[key]);
                }
            });

            return Object.assign(price, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Price.fromJSON(value) : value;
    }
}

// A representation of Price data that can be converted to
// and from JSON without being altered.
interface PriceJSON {
    id?: string;
    product_id?: string;
    price_group_id?: string;
    price?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    price_group?: PriceGroup;
}
