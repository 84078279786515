<div class="row">
    <div *ngIf="isAdmin" class="col-7 mb-3">
        <app-helms-scoreboard></app-helms-scoreboard>
    </div>
    <div *ngIf="!isAdmin" class="col-7 mb-3">
        <h5 class="heading mb-0" i18n>Statistics</h5>
        <a [routerLink]="'custom/statistics'" i18n>Statistics</a>
    </div>
    <div class="col-5 mb-3">
        <h5 class="heading mb-0" i18n>News</h5>
        <div class="tbl  table  table-sm  table-striped  table-hover">
            <table class="tbl__table  tbl__table--has-utilities" style="width: 100%">
                <tbody>
                <ng-template ngFor let-article [ngForOf]="articles">
                    <tr>
                        <td>{{ article.created.date() + '/' + (article.created.month() + 1) + '/' + article.created.year()}}</td>
                        <td>
                            <b>{{ article.title }}</b>
                            <br>
                            <div [innerHTML]="article.body" style="width: 355px"></div>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
            </table>
        </div>
    </div>
    <div  class="col-12">
        <hr/>
        <h5 class="heading"><span i18n>Active orders</span>:</h5>
        <app-helms-orders [embedded]="true" [forSelf]="true"></app-helms-orders>
    </div>
</div>
<hr/>
<div class="row">
    <div class="col-12">
        <h1 class="text-center"></h1>
        <p class="small text-muted text-center"><span i18n>version</span>: {{environment.version}}</p>
    </div>
</div>

