import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {faCheck, faClipboard, faFilePdf, faFloppyDisk, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {OrdersService} from '../../../services/orders.service';
import {Order} from '../../../services/order';
import {BranchesService} from '../../../services/branches.service';
import {OrderStatesService} from '../../../services/order-states.service';
import {DebtorsService} from '../../../services/debtors.service';
import {ReferencesService} from '../../../services/references.service';
import {ProductsService} from '../../../services/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Branch} from '../../../services/branch';
import {OrderState} from '../../../services/order-state';
import {Reference} from '../../../services/reference';
import {Product} from '../../../services/product';
import * as dayjs from 'dayjs';
import {HttpClient} from '@angular/common/http';
import {PrintService} from '../../../services/print.service';
import {environment} from '../../../../environments/environment';
import {MachinesService} from '../../../services/themes/helms/machines.service';
import {OrderLine} from '../../../services/order-line';
import {IdValuesService} from '../../../services/themes/helms/id-values.service';
import {Contact} from '../../../services/contact';
import {CurrenciesService} from '../../../services/currencies.service';
import {Currency} from '../../../services/currency';
import {forkJoin} from 'rxjs';
import {IGroupData} from '../helms-order-edit/helms-order-edit.component';

interface ICustomLine {
    value: string;
    cost: number;
}

@Component({
    selector: 'app-helms-print-friendly-order',
    templateUrl: './helms-print-friendly-order.component.html',
    styleUrls: ['./helms-print-friendly-order.component.scss'],
    providers: [OrdersService, OrderStatesService, ProductsService, DebtorsService, ReferencesService, BranchesService, PrintService, MachinesService, CurrenciesService]
})
export class HelmsPrintFriendlyOrderComponent implements OnInit {

    order: Order;
    product: Product;
    orderId: string;
    productImage: string;
    delivery: Product;
    machine: OrderLine;

    branches: Branch[];
    orderStates: OrderState[];
    references: Reference[];
    currencies: Currency[];
    currency: Currency;

    viewPrint: boolean;
    pdfBlob: string;
    generatingPdf = false;
    savingOffer = false;
    savedOffer = false;
    linkToCustomPDF: string;
    loading = true;
    currencyLoading = true;
    date: any;

    faPDF = faFilePdf;
    faClipBoard = faClipboard;
    faDisk = faFloppyDisk;
    faCheck = faCheck;
    faTrashCan = faTrashCan;

    env = environment;

    printFriendlyInfo = {
        name: '',
        address: '',
        address_field: '',
        zip: '',
        city: '',
        currencyId: '',
        reknr: '',
        date: dayjs(),
        offerText: 'Som opfølgning på vores samtales fremsendes hermed det aftalte tilbud.',
        comments: 'Overnævnte priser er ekskl. moms\n' +
            '\n' +
            'Betaling:\n' +
            '\n' +
            'Kommentar:\n' +
            '\n' +
            'Med venlig hilsen\n' +
            'Helms TMT-Centret A/S - Lager',
        includeDeliveryCosts: false,
        customPrice: 0,
        customLines: [],
        orderLinePrices: [{
            price: 0,
            calculated: false,
        }],
        orderTotal: 0,
    };

    public groupedData: any[];
    private base64Response: any;

    constructor(public ordersService: OrdersService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router,
                public printService: PrintService,
                public http: HttpClient,
                public machinesService: MachinesService,
                public idValuesService: IdValuesService,
                public productsService: ProductsService,
                public currenciesService: CurrenciesService,
                private renderer: Renderer2,
                private elementRef: ElementRef) {
        this.orderId = activatedRoute.snapshot.params.orderId;
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.load(params.get('orderId'));
            // Check if we need to view the page for PDF print:
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get('viewPrint') === 'true') {
                this.viewPrint = true;
            }
        });
    }

    parseGroups(order: Order): void {
        const processed = order.order_lines
            .reduce((r: Map<string, IGroupData>, e) => {
                const group = e.product.product_categories[0].name;
                if (!r[group]) {
                    let sort = 0;
                    switch (group) {
                        // @todo implement sorting on categories og match on id/import ids (breaks with translations)
                        case 'Extraudstyr':
                            sort = 2;
                            break;
                        case 'Hjul':
                            sort = 1;
                            break;
                        case 'Redskaber':
                            sort = 3;
                            break;
                    }
                    r[group] = {group, order_lines: [e], sort};
                } else {
                    r[group].order_lines.push(e);
                }
                return r;
            }, new Map());
        this.groupedData = Object.values(processed)
            .sort((a, b) => {
                if (a.sort > b.sort) {
                    return 1;
                }
                return -1;
            })
        ;
    }


    public load(id): void {
        this.loading = true;
        this.ordersService.getOrder(id, true, true, false, false, true, true, true, true, {withOrderType: true}).subscribe(response => {
            this.order = response.data;

            this.parseGroups(this.order);

            if (this.order?.order_lines[0]?.product?.attachments && this.order?.order_lines[0]?.product?.attachments[0]?.thumbnailUrl('product_list_preview')) {
                this.productImage = this.order.order_lines[0].product.attachments[0].thumbnailUrl('product_list_preview');
            }

            this.machine = this.machinesService.getMachineOrderLine(this.order, true);

            let delivery;
            this.machine?.product.product_relation_childrens.forEach((child) => {
                if (child.product.product_categories[0].id === this.idValuesService.productDeliveryCategoryId) {
                    delivery = child;
                }
            });
            if (delivery) {
                this.productsService.getProduct(delivery.product.id, false, false, true, false, true, false, '', true, false, false, false).subscribe((dResponse) => {
                    this.delivery = dResponse.data;
                });
            }
            this.currenciesService.getCurrencies(1, 10).subscribe((cResponse) => {
                this.currencies = cResponse.data;
                this.fillPrintInfo();
            });
        });
    }

    public fillPrintInfo(): void {
        if (this.order.contacts.length >= 1) {
            const contact = this.order.contacts[0];
            this.printFriendlyInfo.name = contact.name;
            this.printFriendlyInfo.address = contact.address;
            this.printFriendlyInfo.zip = contact.zip;
            this.printFriendlyInfo.city = contact.city;
        }

        this.printFriendlyInfo.reknr = this.order?.getFieldValueForId(this.idValuesService.requisitionFieldValueId);
        this.printFriendlyInfo.address_field = this.order?.getFieldValueForId(this.idValuesService.adressFieldValueId);

        if (!this.printFriendlyInfo.address_field || this.printFriendlyInfo.address_field === '') {
            this.printFriendlyInfo.address_field = this.printFriendlyInfo.address + "\n" +
                this.printFriendlyInfo.zip + "\n" +
                this.printFriendlyInfo.city;
        }


        this.printFriendlyInfo.comments = this.order?.getFieldValueForId(this.idValuesService.commentsFieldValueId);
        this.printFriendlyInfo.offerText = this.order?.getFieldValueForId(this.idValuesService.offerFieldValueId);
        this.date = this.order?.getFieldValueForId(this.idValuesService.dateFieldValueId);
        this.printFriendlyInfo.date = dayjs(this.date);
        if (this.order?.getFieldValueForId(this.idValuesService.customOrderLineFieldValueId)) {
            this.printFriendlyInfo.customLines = JSON.parse(this.order?.getFieldValueForId(this.idValuesService.customOrderLineFieldValueId));
            this.onCustomPriceChange();
        }
        if (this.delivery) {
            this.printFriendlyInfo.customLines.forEach((line) => {
                if (!this.printFriendlyInfo.includeDeliveryCosts) {
                    this.printFriendlyInfo.includeDeliveryCosts = line.isDelivery;
                }
            });
        } else {
            this.printFriendlyInfo.includeDeliveryCosts = false;
        }

        this.printFriendlyInfo.currencyId = this.order?.getFieldValueForId(this.idValuesService.currencyFieldValueId);
        if (!this.printFriendlyInfo.currencyId) {
            this.currencies.forEach((currency) => {
                if (currency.is_default) {
                    this.printFriendlyInfo.currencyId = currency.id;
                    this.onCurrencyChange(currency);
                }
            });
        } else {
            this.currencies.forEach((currency) => {
                if (currency.id === this.printFriendlyInfo.currencyId) {
                    this.loading = true;
                    this.onCurrencyChange(currency);
                    // onCurrencyChange sets loading to false
                }
            });
        }
    }

    public onCustomPriceChange(): void {
        let newCustomPrice = 0;
        this.printFriendlyInfo.customLines.map((customLine) => {
            newCustomPrice = newCustomPrice + customLine.cost;
        });
        this.printFriendlyInfo.customPrice = newCustomPrice;
    }

    public onAddRowClick(isDelivery: boolean): void {
        if (isDelivery) {
            this.printFriendlyInfo.customLines.push({
                value: this.delivery.name,
                cost: this.delivery.price,
                isDelivery: true,
            });
        } else {
            this.printFriendlyInfo.customLines.push({
                value: '',
                cost: 0,
                isDelivery: false,
            });
        }
        this.onCustomPriceChange();
    }

    public onRemoveRowClick(index: number): void {
        if (index >= 0) {
            const confirmAction = confirm('Er du sikker på du vil slette denne linje? Du kan IKKE fortryde dette valg!');
            if (confirmAction) {
                // If the clicked line is delivery, update button
                if (this.printFriendlyInfo.customLines[index].isDelivery) {
                    this.printFriendlyInfo.includeDeliveryCosts = false;
                }
                this.printFriendlyInfo.customLines = this.printFriendlyInfo.customLines.filter((item, i) => {
                    return index !== i;
                });
            }
        } else {
            this.printFriendlyInfo.customLines = this.printFriendlyInfo.customLines.filter((item, i) => {
                return !item.isDelivery;
            });
        }
        this.onCustomPriceChange();
    }

    public onSaveOffer(): void {
        // console.log(this.printFriendlyInfo);
        // const encodedData = encodeURIComponent(btoa(JSON.stringify(this.printFriendlyInfo)));
        this.updateOrder();
        this.savingOffer = true;
        this.ordersService.updateOrder(this.order).subscribe((response) => {
            this.savingOffer = false;
            this.savedOffer = true;
            setTimeout(() => {
                this.savedOffer = false;
            }, 2000);
        });
    }

    public onDeliveryCostChange(val: boolean): void {
        if (this.delivery) {
            if (val) {
                this.onAddRowClick(true);
            } else {
                this.onRemoveRowClick(-1);
            }
            this.printFriendlyInfo.includeDeliveryCosts = val;
        }
    }

    public onCurrencyChange(currency?: Currency): void {

        if (currency) {
        } else {
            currency = this.currencies.find((crr) => {
                return crr.id === this.printFriendlyInfo.currencyId;
            });
        }
        this.currency = currency;
        this.currencyLoading = true;
        this.ordersService.getOrder(this.orderId, true, true, true, false, true, true, true, true, {
            withOrderType: true,
            forCurrency: this.currency.id
        }).subscribe(response => {
            this.order = response.data;
            this.parseGroups(this.order);

            this.printFriendlyInfo.orderTotal = 0;
            this.order.order_lines.forEach((data: any) => {
                this.printFriendlyInfo.orderTotal += data.exch_price;
            });

            this.loading = false;
            this.currencyLoading = false;
        });

        // const observables = [];
        // this.order.order_lines.forEach((orderLine) => {
        //     // tslint:disable-next-line:max-line-length
        //     observables.push(this.productsService.getProduct(orderLine.product.id, true, true, true, true, true, true, this.order.debtor_id, false, false, true, true, {forCurrency: currency.id, withAdjustedPrices: true}));
        // });
        // forkJoin(observables).subscribe(joinData => {
        //     this.printFriendlyInfo.orderTotal = 0;
        //     this.printFriendlyInfo.orderLinePrices = [];
        //     joinData.forEach((data: any) => {
        //         const orderLinePrice = {
        //             price: 0,
        //             calculated: false,
        //         };
        //         orderLinePrice.price = data.data.adjusted_price;
        //         // is_exchanged is a virtual field that determines whether the price has been calculated from currency
        //         orderLinePrice.calculated = data.data.is_exchanged;
        //         this.printFriendlyInfo.orderLinePrices.push(orderLinePrice);
        //         this.printFriendlyInfo.orderTotal += data.data.adjusted_price;
        //     });
        //     this.loading = false;
        //     this.currencyLoading = false;
        // });
    }

    public onGenerateClick(): void {
        this.generatingPdf = true;
        this.updateOrder();
        // @todo - nested subscribtions
        this.ordersService.updateOrder(this.order).subscribe((response) => {
            this.printService.getContentPdf(window.location.href + '?viewPrint=true', '').subscribe((innerResponse:any) => {
                this.processFileResponse(innerResponse.data.pdf, 'pdf.pdf', 'base64');
                this.generatingPdf = false;
                // tslint:disable-next-line:no-string-literal
                // const newBlob = new Blob([atob(innerResponse.data['pdf'])], {type: 'application/pdf'});
                // const downloadLink = document.createElement('a');
                // downloadLink.target = '_self';
                // const fileName = 'pdf_file.pdf';
                // downloadLink.href = window.URL.createObjectURL(newBlob);
                // downloadLink.download = fileName;
                // document.body.appendChild(downloadLink);
                // downloadLink.click();
            });
        });
    }

    private processFileResponse(fileResponseData: any, fileName: string, render: string): void {
        if (render === 'base64') {
            this.base64Response = fileResponseData;
            const binaryString = window.atob(fileResponseData);
            const bytes = new Uint8Array(binaryString.length);
            const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
            const blob = new Blob([binaryToBlob], { type: 'application/pdf' });
            this.downloadFile(blob, fileName);
        } else {
            const blob = new Blob([fileResponseData], { type: 'application/pdf' });
            this.downloadFile(blob, fileName);
        }
    }

    private downloadFile(blob: any, fileName: string): void {
// IE Browser
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
            return;
        }
// Other Browsers
        const url = (window.URL || window.webkitURL).createObjectURL(blob);
        const link = this.renderer.createElement('a');
        this.renderer.setAttribute(link, 'download', fileName);
        this.renderer.setAttribute(link, 'href', url);
        this.renderer.setAttribute(link, 'target', '_blank');
        this.renderer.appendChild(this.elementRef.nativeElement, link);
        link.click();
        this.renderer.removeChild(this.elementRef.nativeElement, link);

        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    }


    private updateOrder(): void {

        this.order.setFieldValueForId(this.idValuesService.commentsFieldValueId, this.printFriendlyInfo.comments);
        this.order.setFieldValueForId(this.idValuesService.offerFieldValueId, this.printFriendlyInfo.offerText);
        this.order.setFieldValueForId(this.idValuesService.currencyFieldValueId, this.printFriendlyInfo.currencyId);
        this.order.setFieldValueForId(this.idValuesService.dateFieldValueId, this.date);
        this.order.setFieldValueForId(this.idValuesService.requisitionFieldValueId, this.printFriendlyInfo.reknr);
        this.order.setFieldValueForId(this.idValuesService.adressFieldValueId, this.printFriendlyInfo.address_field);

        const customLines = JSON.stringify(this.printFriendlyInfo.customLines);
        this.order.setFieldValueForId(this.idValuesService.customOrderLineFieldValueId, customLines);

        this.order.order_lines.forEach((orderLine) => {
            if (this.machinesService.checkMachineOrderLine(orderLine)) {
            }
        });

        if (this.order.contacts.length === 0) {
            const contact = new Contact();
            contact.name = this.printFriendlyInfo.name;
            contact.address = this.printFriendlyInfo.address;
            contact.zip = this.printFriendlyInfo.zip;
            contact.city = this.printFriendlyInfo.city;
            contact.nickname = this.printFriendlyInfo.name;
            this.order.contacts.push(contact);
        } else {
            this.order.contacts[0].name = this.printFriendlyInfo.name;
            this.order.contacts[0].address = this.printFriendlyInfo.address;
            this.order.contacts[0].zip = this.printFriendlyInfo.zip;
            this.order.contacts[0].city = this.printFriendlyInfo.city;
        }
    }

    public handleDate(): void {
        this.printFriendlyInfo.date = dayjs(this.date);
    }
}
