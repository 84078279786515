<div class="container  pb-5">
    <h1>
        <ng-container *ngIf="!productId" i18n>Add product</ng-container>
        <ng-container *ngIf="productId" i18n>Edit product</ng-container>
        <small *ngIf="loading" class="text-muted" i18n>Loading...</small>
    </h1>
    <div *ngIf="_product && languagesService.languages">
        <div class="form-group" style="position: absolute; top: 0; right: 100px">
            <input type="checkbox" id="product-active" name="active" [(ngModel)]="_product.is_active">
            <label for="product-active" class="ml-1 mr-5 mt-4 h3" i18n>Active</label>
        </div>
    </div>

    <p *ngIf="!_product" class="text-muted" i18n>Loading...</p>

    <div *ngIf="_product && languagesService.languages">
        <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>


        <form>
            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label for="product-name" i18n>Name</label><app-language-indicator></app-language-indicator>
                        <input type="text" id="product-name" class="form-control" [(ngModel)]="name[languagesService.index]" name="name">
                    </div>

                    <div class="form-group">
                        <label i18n>Date start</label>

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="date_start" i18n-placeholder placeholder="Start date" [(ngModel)]="_product.date_start" ngbDatepicker #dateStart="ngbDatepicker">

                            <div class="input-group-append">
                                <button (click)="dateStart.toggle()" class="btn  btn-outline-secondary" type="button" i18n>Date picker</button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label i18n>Date end</label>

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="date_end" i18n-placeholder placeholder="End date" [(ngModel)]="_product.date_end" ngbDatepicker #dateEnd="ngbDatepicker">

                            <div class="input-group-append">
                                <button (click)="dateEnd.toggle()" class="btn  btn-outline-secondary" type="button" i18n>Date picker</button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label i18n>Creditor</label>

                        <select class="form-control" name="creditor_id" [(ngModel)]="_product.creditor_id">
                            <option [ngValue]="null" i18n>-- Select creditor --</option>
                            <option *ngFor="let creditor of creditors" [ngValue]="creditor.id">{{ creditor.name }}</option>
                        </select>
                    </div>

                    <ng-template ngFor let-reference [ngForOf]="_product.references">
                        <div class="form-group">
                            <label for="product-reference-{{ reference.id }}-value">{{ reference.name }} <small class="text-muted" i18n>(reference)</small></label>
                            <input type="text" id="product-reference-{{ reference.id }}-value" class="form-control" [placeholder]="reference.name" [(ngModel)]="reference._joinData.value" name="{{ reference.id }}-value">
                        </div>
                    </ng-template>

                    <ng-template ngFor let-productField [ngForOf]="_product.product_fields" let-i="index">
                        <ng-container *ngIf="shouldShowProductField(productField)">
                            <div *ngIf="productField.type === 'text'" class="form-group">
                                <label for="product-product-field-{{ productField.id }}-value-text">{{ productField.name }} <small class="text-muted" i18n>(product field)</small></label><app-language-indicator *ngIf="productField.is_localized"></app-language-indicator>

                                <input *ngIf="productField.is_localized" type="text" id="product-product-field-{{ productField.id }}-value-text" class="form-control" [placeholder]="productField.name" [(ngModel)]="fieldTranslations[i][languagesService.index]" name="{{ productField.id }}-value-text">
                                <input *ngIf="!productField.is_localized" type="text" id="product-product-field-{{ productField.id }}-value-text" class="form-control" [placeholder]="productField.name" [(ngModel)]="_products_product_fields[i].value" name="{{ productField.id }}-value-text">
                            </div>

                            <div *ngIf="productField.type === 'checkbox'" class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" [(ngModel)]="productField._joinData.value" name="{{ productField.id }}-value-checkbox"> {{ productField.name }} <small class="text-muted" i18n>(product field)</small>
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="productField.type === 'link'" class="form-group">
                                <label for="product-product-field-{{ productField.id }}-value-text">{{ productField.name }} <small class="text-muted" i18n>(product field)</small></label><app-language-indicator *ngIf="productField.is_localized"></app-language-indicator>

                                <div class="row">
                                    <div class="col-md-6">
                                        <input *ngIf="productField.is_localized" type="text" id="product-product-field-{{ productField.id }}-value-text" class="form-control" i18n-placeholder placeholder="Link title" [(ngModel)]="fieldTranslations[i][languagesService.index]" name="{{ productField.id }}-value-text">
                                        <input *ngIf="!productField.is_localized" type="text" id="product-product-field-{{ productField.id }}-value-text" class="form-control" i18n-placeholder placeholder="Link title" [(ngModel)]="_products_product_fields[i].value" name="{{ productField.id }}-value-text">
                                    </div>

                                    <div class="col-md-6">
                                        <input type="text" id="product-product-field-{{ productField.id }}-meta-text" class="form-control" name="{{ productField.id }}-meta-text" i18n-placeholder placeholder="URL" [(ngModel)]="_products_product_fields[i].meta">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>
                </div>

                <div class="col-md-6">
                    <div class="alert  alert-secondary">
                        <ng-container i18n>Adjusted price</ng-container>: <b>{{ _product.adjusted_price | currency:'DKK':'':null }}</b>
                        <span class="text-muted  mx-3">|</span>
                        <ng-container i18n>Cost price</ng-container>: <b>{{ _product.cost_price | currency:'DKK':'':null }}</b>
                        <span class="text-muted  mx-3">|</span>
                        <ng-container i18n>Adjusted cost price</ng-container>: <b>{{ _product.cost_price_adjusted | currency:'DKK':'':null }}</b>
                    </div>

                    <p *ngIf="_product.prices.length === 0" class="text-muted" i18n>No prices</p>

                    <a href="javascript:" (click)="disablePriceFilter = !disablePriceFilter">{{disablePriceFilter?'Skjul inaktive prislister':'Vis alle priser'}}</a>

                    <table *ngIf="_product.prices.length > 0" class="table  table-sm  table-striped  table-hover">
                        <thead>
                        <tr>
                            <th i18n>Price</th>
                            <th i18n>Price group</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                            <ng-template ngFor let-price [ngForOf]="_product.prices | priceFilter:'price_group.is_inactive':false:disablePriceFilter" let-i="index">
                                <tr>
                                    <td>
                                        <input type="text" class="form-control  form-control-sm" [(ngModel)]="price.price" name="name-{{ i }}">
                                    </td>
                                    <td>
                                        {{ price.price_group.name }}
                                        <span *ngIf="price.price_group.is_cost_price" class="badge  badge-primary  ml-2" i18n>Cost price</span>
                                        <span *ngIf="price.price_group.is_default" class="badge  badge-primary  ml-2" i18n>Default</span>

                                        <fa-icon [icon]="faXmark" [ngbTooltip]="'Inactive'" *ngIf="price.price_group.is_inactive"></fa-icon>

                                    </td>
                                    <td class="text-right">
                                        <a href="javascript:" (click)="_product.prices.splice(i, 1)" class="text-danger" i18n>Remove</a>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>

                    <button (click)="addPrice()" class="btn  btn-sm  btn-primary" i18n>Add price</button>

                    <hr>

                    <p class="text-muted" i18n>Quantity discounts</p>

                    <p *ngIf="_product.product_quantity_discounts.length === 0" class="text-muted" i18n>No quantity discounts</p>

                    <table *ngIf="_product.product_quantity_discounts.length > 0" class="table  table-sm  table-striped  table-hover">
                        <thead>
                        <tr>
                            <th i18n>Minimum</th>
                            <th i18n>Percentage</th>
                            <th i18n>Minimum multiple</th>
                            <th i18n>Price group</th>
                            <th i18n>Price adjustment</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                            <ng-template ngFor let-productQuantityDiscount [ngForOf]="_product.product_quantity_discounts">
                                <tr>
                                    <td>{{ productQuantityDiscount.min }}</td>
                                    <td>{{ productQuantityDiscount.pct }} %</td>
                                    <td>
                                        <span *ngIf="productQuantityDiscount.requires_min_multiple" i18n>Yes</span>
                                        <span *ngIf="!productQuantityDiscount.requires_min_multiple" i18n>No</span>
                                    </td>
                                    <td>{{ productQuantityDiscount.price_group_id }}</td>
                                    <td>{{ productQuantityDiscount.price_adjustment_id }}</td>
                                    <td class="text-right">
                                        <button (click)="editQuantityDiscount(productQuantityDiscount)" class="btn  btn-sm  btn-primary" i18n>Edit</button>
                                        <button (click)="deleteQuantityDiscount(productQuantityDiscount)" class="btn  btn-sm  btn-danger" i18n>Delete</button>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>

                    <button (click)="addQuantityDiscount()" class="btn  btn-sm  btn-primary" i18n>Add quantity discount</button>

                    <hr>

                    <p class="text-muted" i18n>VAT</p>

                    <div class="form-group">
                        <select class="form-control" name="vat_type_id" [(ngModel)]="_product.vat_type_id">
                            <option *ngFor="let vatType of vatTypes" [ngValue]="vatType.id">{{ vatType.name }} - {{ vatType.rate | number }}%</option>
                        </select>
                    </div>

                    <hr>

                    <p class="text-muted" i18n>Unit</p>

                    <div class="form-group">
                        <select class="form-control" name="unit_id" [(ngModel)]="_product.unit_id">
                            <option *ngFor="let unit of units" [ngValue]="unit.id">{{ unit.name }}</option>
                        </select>
                    </div>

                    <hr>

                    <p class="text-muted" i18n>Alternative price units</p>

                    <table *ngIf="_product.alternative_price_units.length > 0" class="table  table-sm  table-striped  table-hover">
                        <thead>
                            <tr>
                                <th i18n>Unit</th>
                                <th i18n>Factor</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-template ngFor let-alternativePriceUnit [ngForOf]="_product.alternative_price_units" let-i="index">
                                <tr>
                                    <td><input type="text" class="form-control  form-control-sm" [(ngModel)]="alternativePriceUnit.unit" name="alternative-price-unit-{{ i }}-unit"></td>
                                    <td><input type="text" class="form-control  form-control-sm" [(ngModel)]="alternativePriceUnit.factor" name="alternative-price-unit-{{ i }}-factor"></td>
                                    <td class="text-right">
                                        <a href="javascript:" (click)="_product.alternative_price_units.splice(i, 1)" class="text-danger" i18n>Remove</a>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>

                    <button (click)="_product.addAlternativePriceUnit()" class="btn  btn-sm  btn-primary" i18n>Add alternative price unit</button>

                    <hr>

                    <p class="text-muted" i18n>Categories</p>

                    <div class="mb-3">
                        <ul class="list-unstyled">
                            <ng-template ngFor let-productCategory [ngForOf]="_product.product_categories" let-i="index">
                                <li>
                                    <span class="badge  badge-secondary  mr-2">{{ productCategory.name }}</span>

                                    <a href="javascript:" (click)="removeCategory(i)" class="text-danger" i18n>Remove</a>
                                </li>
                            </ng-template>
                        </ul>

                    </div>

                    <button (click)="addCategory()" class="btn  btn-sm  btn-primary">Add category</button>

                    <p class="text-muted" i18n>Product stocks</p>

                    <table class="table  table-sm  table-striped">
                        <thead>
                            <tr>
                                <td style="width: 100px;" i18n>Quantity</td>
                                <td style="width: 100px;" i18n>Predicted</td>
                                <td i18n>Location reference</td>
                                <td i18n>Branch</td>
                                <td></td>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-template ngFor let-productStock [ngForOf]="_product.product_stocks" let-i="index">
                                <tr>
                                    <td><input type="number" min="0" class="form-control  form-control-sm" name="product-product-stock-{{ i }}-quantity" [(ngModel)]="productStock.quantity"></td>
                                    <td><input type="number" class="form-control  form-control-sm" name="product-product-stock-{{ i }}-predicted" [(ngModel)]="productStock.predicted" [disabled]="true"></td>
                                    <td><input type="text" class="form-control  form-control-sm" name="product-product-stock-{{ i }}-location-reference" [(ngModel)]="productStock.location_reference"></td>
                                    <td>{{ productStock.branch.name }}</td>
                                    <td class="text-right"><button (click)="_product.product_stocks.splice(i, 1)" class="btn  btn-sm  btn-danger" i18n>Remove</button></td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>

                    <button (click)="addProductStock()" class="btn  btn-sm  btn-primary" i18n>Add product stock</button>

                    <p class="text-muted" i18n>Attachments</p>
                    <app-attachments [attachments]="_product.attachments" [embedded]="true"></app-attachments>
                    <button (click)="addAttachment()" class="btn  btn-sm  btn-primary" i18n>Add attachment</button>

                </div>
            </div>
        </form>
    </div>
</div>
