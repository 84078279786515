import {Component, OnInit, ViewChild} from '@angular/core';
import {ChartType, ChartOptions} from 'chart.js';
import {
    BaseChartDirective,
    Label,
    monkeyPatchChartJsLegend,
    monkeyPatchChartJsTooltip,
    SingleDataSet
} from 'ng2-charts';
import {ProductsQueryInterface, ProductsService} from '../../../services/products.service';
import {Product} from '../../../services/product';
import {DebtorsQueryInterface, DebtorsService} from '../../../services/debtors.service';
import {Debtor} from '../../../services/debtor';
import {IdValuesService} from '../../../services/themes/helms/id-values.service';
import {AuthService} from '../../../services/auth.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-helms-statistics',
    templateUrl: './helms-statistics.component.html',
    styleUrls: ['./helms-statistics.component.scss'],
    providers: [DebtorsService, ProductsService, IdValuesService]
})
export class HelmsStatisticsComponent implements OnInit {

    @ViewChild( BaseChartDirective ) chart: BaseChartDirective;

    public loading: number = 0;

    limit = 250;

    active;

    private authedPermissions: Observable<any[]>;
    public isAdmin = false;

    public years: number[] = [];
    public months: any[] = [
        { number: 1, name: 'Januar' },
        { number: 2, name: 'Februar' },
        { number: 3, name: 'Marts' },
        { number: 4, name: 'April' },
        { number: 5, name: 'Maj' },
        { number: 6, name: 'Juni' },
        { number: 7, name: 'Juli' },
        { number: 8, name: 'August' },
        { number: 9, name: 'September' },
        { number: 10, name: 'Oktober' },
        { number: 11, name: 'November' },
        { number: 12, name: 'December' },
    ];

    public monthStrings = [
        '',
        'Januar',
        'Februar',
        'Marts',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'December',
    ];

    public year: number = (new Date()).getFullYear();
    public month: number;
    public debtorId: string = null;

    public debtorData = [];
    public productData = [];

    public debtors: Debtor[];
    public products: Product[];

    public debtorsPieChartLabels: Label[] = [];
    public debtorsPieChartData: SingleDataSet = [];

    public productsPieChartLabels: Label[] = [];
    public productsPieChartData: SingleDataSet = [];

    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: undefined,
        maintainAspectRatio: false,
    };
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];
    public productTotals: { totalOrders: any };
    public debtorTotals: { totalOrders: any };

    constructor(public debtorsService: DebtorsService,
                public productsService: ProductsService,
                public idValuesService: IdValuesService,
                public authService: AuthService) {
        monkeyPatchChartJsTooltip();
        monkeyPatchChartJsLegend();
        this.authedPermissions = this.authService.authedPermissionsDataStream();
    }

    ngOnInit(): void {
        for (let i = 2004; i <= (new Date).getFullYear(); i++) {
            this.years.push(i);
        }

        this.authedPermissions.subscribe((result) => {
            this.isAdmin = this.authService.hasPermission('admin');
        });

        this.load();
    }

    load(): void {
        console.log('helms-statistics::load');

        const debtorsQuery: DebtorsQueryInterface = {
            withStatistics: this.idValuesService.orderedOrderStateId
        };

        const productsQuery: ProductsQueryInterface = {
            withStatistics: true,
            byCategories: this.idValuesService.productMachineCategoryId
        };

        if (this.year) {
            debtorsQuery.statisticsYear = this.year;
            productsQuery.statisticsYear = this.year;
        }

        if (this.month) {
            debtorsQuery.statisticsMonth = this.month;
            productsQuery.statisticsMonth = this.month;
        }

        if (this.debtorId) {
            productsQuery.byOrderDebtorId = this.debtorId;
        }

        this.loading++;

        this.debtorsService.getDebtors(1, this.limit, false, false, false, true, false, debtorsQuery).subscribe(response => {
            this.debtors = response.data;

            this.debtorData = this.debtors.map((debtor) => {
                const zipcode = debtor.contacts.length > 0 ? debtor.contacts[0].zip : '';

                return {
                    name: debtor.name,
                    zipcode: zipcode,
                    offers: debtor['offers_this_period'],
                    offersConversion: debtor['offers_conversion_this_period'],
                    count: debtor['orders_this_period'] ? debtor['orders_this_period'] : 0,
                    lastCount: debtor['orders_last_period'],
                    laterCount: debtor['orders_later_period'],
                    logins: debtor['logins'],
                    lastLogin: debtor['last_login']
                };
            });

            this.debtorsPieChartData = [];
            this.debtorsPieChartLabels = [];

            this.debtorData.map((item) => {
                this.debtorsPieChartData.push(item.count);
                this.debtorsPieChartLabels.push(item.name);
            });

            console.log(this.debtorData);

            this.debtorTotals = {
                totalOrders: this.debtorData.reduce((sum, item) => +sum + +item.count, 0)
            };

            this.loading--;
        });

        this.loading++;

        this.productsService.getProducts(1, this.limit, false, false, false, false, false, false, productsQuery).subscribe(response => {
            this.products = response.data;

            this.productData = this.products.map((product) => {
                return {
                    name: product.name,
                    offers: product['offers_this_period'],
                    offersConversion: product['offers_conversion_this_period'],
                    count: product['orders_this_period'] ? product['orders_this_period'] : 0,
                    lastCount: product['orders_last_period'],
                    laterCount: product['orders_later_period']
                };
            });

            this.productsPieChartData = [];
            this.productsPieChartLabels = [];

            this.productData.map((item) => {
                this.productsPieChartData.push(item.count);
                this.productsPieChartLabels.push(item.name);
            });

            this.productTotals = {
                totalOrders: this.productData.reduce((sum, item) => +sum + +item.count, 0)
            };

            this.loading--;
        });
    }

    onRowEnter(index: number): void {
        let mouseMoveEvent;
        let meta;
        let point;
        let rectangle;

        meta = this.chart.chart.getDatasetMeta(0);
        rectangle = this.chart.chart.canvas.getBoundingClientRect();
        point = meta.data[index].getCenterPoint();

        mouseMoveEvent = new MouseEvent('mousemove', {
            clientX: rectangle.left + point.x,
            clientY: rectangle.top + point.y
        });

        this.chart.chart.canvas.dispatchEvent(mouseMoveEvent);
    }

    onRowLeave(): void {
        let mouseMoveEvent;

        mouseMoveEvent = new MouseEvent('mousemove', {
            clientX: 0,
            clientY: 0
        });

        this.chart.chart.canvas.dispatchEvent(mouseMoveEvent);
    }
}
