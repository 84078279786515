<div class="helms-scoreboard">
    <svg xmlns="http://www.w3.org/2000/svg" class="svg-settings">
        <defs>
            <radialGradient id="gold">
                <stop class="start" offset="0%"></stop>
                <stop class="stop" offset="100%"></stop>
            </radialGradient>
            <radialGradient id="silver">
                <stop class="start" offset="0%"></stop>
                <stop class="stop" offset="100%"></stop>
            </radialGradient>
            <radialGradient id="bronze">
                <stop class="start" offset="0%"></stop>
                <stop class="stop" offset="100%"></stop>
            </radialGradient>
        </defs>
    </svg>

    <div *ngIf="topSellersThisMonth && topSellersThisMonth.length < 3" class="helms-scoreboard__awards  text-center  mb-4">
        Afventer salgstal
    </div>
    <div *ngIf="topSellersThisMonth && topSellersThisMonth.length > 2" class="helms-scoreboard__awards  text-center  mb-4">

        <div class="row">
            <div class="col-12">
                <h4 class="helms-scoreboard__title  my-2"><span i18n>Top sellers</span> {{ monthStrings[now.month()] }} {{ now.year() }}</h4>
            </div>
        </div>
        <div class="row  align-items-end">
            <div class="col-md-4  order-md-2">
                <fa-icon [icon]="faTrophy" class="helms-scoreboard__award  helms-scoreboard__award--gold"></fa-icon>
                <span class="helms-scoreboard__name">{{ topSellersThisMonth[0].name }}</span>
            </div>
            <div class="col-md-4  order-md-1" *ngIf="topSellersThisMonth[1]">
                <fa-icon [icon]="faTrophy" class="helms-scoreboard__award  helms-scoreboard__award--silver"></fa-icon>
                <span class="helms-scoreboard__name">{{ topSellersThisMonth[1].name }}</span>
            </div>
            <div class="col-md-4  order-md-3" *ngIf="topSellersThisMonth[2]">
                <fa-icon [icon]="faTrophy" class="helms-scoreboard__award  helms-scoreboard__award--bronze"></fa-icon>
                <span class="helms-scoreboard__name">{{ topSellersThisMonth[2].name }}</span>
            </div>
        </div>
    </div>

    <a [routerLink]="'custom/statistics'" i18n *ngIf="isAdmin">More statistics</a>

<!--    <div *ngIf="topSellersLastMonth && topSellersThisMonth" class="row">-->
<!--        <div class="col-12  col-md-6  mb-4  mb-md-0">-->
<!--            <div class="helms-scoreboard__awards  helms-scoreboard__awards&#45;&#45;subtle">-->
<!--                <p class="helms-scoreboard__title  mt-4  mb-0">Topsælgere {{ monthStrings[lastMonth.month()] }} {{ lastMonth.year() }}</p>-->
<!--                <ol class="pl-3">-->
<!--                    <ng-template ngFor let-debtor [ngForOf]="topSellersLastMonth">-->
<!--                        <li>-->
<!--                            <span class="float-left">{{ debtor?.name }}</span>-->
<!--                            <strong class="float-right">{{ debtor['orders_this_period'] }}</strong>-->
<!--                        </li>-->
<!--                    </ng-template>-->
<!--                </ol>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="col-12  col-md-6">-->
<!--            <div class="helms-scoreboard__awards  helms-scoreboard__awards&#45;&#45;subtle">-->
<!--                <p class="helms-scoreboard__title  mt-4  mb-0">Topsælgere {{ monthStrings[now.month()] }} {{ now.year() }}</p>-->
<!--                <ol class="pl-3">-->
<!--                    <ng-template ngFor let-debtor [ngForOf]="topSellersThisMonth">-->
<!--                        <li>-->
<!--                            <span class="float-left">{{ debtor?.name }}</span>-->
<!--                            <strong class="float-right">{{ debtor['orders_this_period'] }}</strong>-->
<!--                        </li>-->
<!--                    </ng-template>-->
<!--                </ol>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>
