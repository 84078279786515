import {Component, OnInit} from '@angular/core';
import {
    faBasketShopping,
    faCamera,
    faCartPlus,
    faListCheck,
    faSquare,
    faSquareCheck,
    faTimes,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductsService} from '../../../services/products.service';
import {Product} from '../../../services/product';
import {OfferGeneratorService} from "../../../services/offer-generator.service";
import {Contact} from "../../../services/contact";


@Component({
    selector: 'app-helms-tools-order',
    templateUrl: './helms-tools-order.component.html',
    styleUrls: ['./helms-tools-order.component.scss'],
    providers: [ProductsService]
})
export class HelmsToolsOrderComponent implements OnInit {

    tools: Product[] = [];
    toolCount: number;
    currentPage = 1;
    isFetchingMoreTools = false;

    loadingComparison = false;
    toolWithRelation: Product;
    allProducts: Product[];

    faCamera = faCamera;
    faListCheck = faListCheck;
    faTimes = faTimes;
    faBasket = faCartPlus;
    faSquareCheck = faSquareCheck;
    faSquare = faSquare;

    productImageURL: string;

    constructor(public productsService: ProductsService, public modalService: NgbModal, public offerService: OfferGeneratorService) {
        this.updateNextStatus();
    }

    updateNextStatus() {
        if (this.offerService.selectedExtraTools.length > 0) {
            this.offerService.pausenext = false;
        } else {
            this.offerService.pausenext = true;
        }
    }

    ngOnInit(): void {

        this.offerService.updates$.subscribe(response => {
            if (response) {
                this.updateNextStatus();
            }
        });

        this.loadAdditionalTools();
        // this.productsService.getProducts(this.currentPage, 50, false, false, true, false, false, false, {
        //     byCategories: '0cd897bc-178b-46cb-a42e-ce36b744545e',
        //     withProductRelations: false,
        //     withProductStocks: true,
        //     withAdjustedPrices: true,
        // }).subscribe((response) => {
        //     this.toolCount = response.count;
        //     this.tools = response.data;
        //     this.currentPage++;
        //     console.log(this.tools);
        // });
    }

    loadAdditionalTools(): void {
        if (this.toolCount > this.tools.length || !this.toolCount) {
            this.isFetchingMoreTools = true;
            this.productsService.getProducts(this.currentPage, 100, false, false, true, false, false, false, {
                byCategories: '0cd897bc-178b-46cb-a42e-ce36b744545e',
                withProductRelations: false,
                withProductStocks: true,
                withAdjustedPrices: true,
                withAttachments: true,
                onlyActive: true
            }).subscribe((response) => {
                this.toolCount = response.count;
                this.isFetchingMoreTools = false;
                this.tools = [...this.tools, ...response.data];
                this.currentPage++;
            });
        }
    }

    openComparison(content, tool: Product): void {
        this.loadingComparison = true;
        this.productsService.getProduct(
            tool.id,
            false,
            false,
            false,
            false,
            false,
            false,
            '',
            false,
            true,
            false,
            false,
            {}).subscribe((toolWithRelations) => {
            this.toolWithRelation = toolWithRelations.data;
            if (!this.allProducts) {
                this.productsService.getProducts(
                    1,
                    -1,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    {
                        byCategories: 'ddeb6131-7a39-4c8a-bc9a-849151b57141'
                    }).subscribe((allProducts) => {
                    this.allProducts = allProducts.data;
                    this.loadingComparison = false;
                });
            } else {
                this.loadingComparison = false;
            }
        });
        this.modalService.open(content, {size: 'lg'}).result.then((result) => {
        }, (reason) => {
            this.toolWithRelation = undefined;
        });
    }

    onAdd(product: Product): void {
        this.offerService.onAddClick(product);
    }

    onRemove(product: Product): void {
        this.offerService.onRemoveClick(product);
    }

    isCompatible(id: string): boolean {
        const findItem = this.toolWithRelation.product_relation_parents.find((product) => {
            return product.product_id === id;
        });
        if (findItem) {
            return true;
        } else {
            return false;
        }
    }

    openImage(content, id): void {

        this.modalService.open(
            content,
            {
                modalDialogClass: 'helms-tool-order__modal  helms-tool-order__modal--image',
                centered: true
            }).result.then((result) => {
        }, (reason) => {
            this.productImageURL = undefined;
        });

        this.productsService.getProduct(id, false, false, false, false, false, false, null, false, false, false, false, {withAttachments: true}).subscribe((response) => {
            if (response.data.attachments && response.data.attachments.length > 0) {
                this.productImageURL = response.data.getThumbnail('product_list_preview');
            }
        });
    }
}
