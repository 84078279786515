import {Injectable} from '@angular/core';
import {Product} from './product';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Empty} from "./empty";
import {ProductImportMappingColumn} from "./product-import-mapping-column";

@Injectable()
export class ProductsService {

    protected url = environment.apiBase + 'products';

    constructor(public apiService: ApiService) {
    }

    getProduct(id: string, withCreditor: boolean, withVatType: boolean, withPrices: boolean, withReferences: boolean, withCategories: boolean, withProductFields: boolean, withAdjustedPriceForDebtor: string, withAdjustedCostPrice: boolean, withProductRelations: boolean, withProductStocks: boolean, withAlternativePriceUnits: boolean, query?: ProductsQueryInterface): Observable<{ data: Product, count: number, perPage: number }> {
        let url = this.url + '/' + id;

        let parameters = [];

        if (withCreditor) {
            parameters.push('with-creditor=1');
        }

        if (withVatType) {
            parameters.push('with-vat-type=1');
        }

        if (withPrices) {
            parameters.push('with-prices=1');
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (withCategories) {
            parameters.push('with-categories=1');
        }

        if (withProductFields) {
            parameters.push('with-product-fields=1');
        }

        if (withAdjustedPriceForDebtor) {
            parameters.push('with-adjusted-price=' + withAdjustedPriceForDebtor);
        }

        if (withAdjustedCostPrice) {
            parameters.push('with-adjusted-cost-price=1');
        }

        if (withProductRelations) {
            parameters.push('with-product-relations=1');
        }

        if (withProductStocks) {
            parameters.push('with-product-stocks=1');
        }

        if (withAlternativePriceUnits) {
            parameters.push('with-alternative-price-units=1');
        }

        if (query && query.withProductQuantityDiscounts) {
            parameters.push('with-product-quantity-discounts=1');
        }

        if (query && query.withAdjustedPrices) {
            parameters.push('with-adjusted-prices=1');
        }

        if (query && query.withAttachments) {
            parameters.push('with-attachments=1');
        }

        if (query && query.forCurrency) {
            parameters.push('for-currency=' + query.forCurrency);
        }

        if (query && query.fromOrderId) {
            parameters.push('fromOrderId=' + query.fromOrderId);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Product>(url, true, Product);
    }

    getProducts(page: number, limit: number, withCreditor: boolean, withVatType: boolean, withPrices: boolean, withReferences: boolean, withCategories: boolean, withAlternativePriceUnits: boolean, query?: ProductsQueryInterface): Observable<{ data: Product[], count: number, perPage: number }> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            if (limit === -1) {
                parameters.push('all=1');
            } else {
                parameters.push('limit=' + limit);
            }
        }

        if (withCreditor) {
            parameters.push('with-creditor=1');
        }

        if (withVatType) {
            parameters.push('with-vat-type=1');
        }

        if (withPrices) {
            parameters.push('with-prices=1');
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (withCategories) {
            parameters.push('with-categories=1');
        }

        if (withAlternativePriceUnits) {
            parameters.push('with-alternative-price-units=1');
        }

        if (query) {
            if (query && query.forProductName) {
                parameters.push('for-product-name=' + query.forProductName);
            }

            if (query && query.forProductReference) {
                parameters.push('for-product-reference=' + query.forProductReference);
                parameters.push('for-product-reference-value=' + query.forProductReferenceValue);
            }

            if (query.onlyActive && query.onlyActive === true) {
                parameters.push('only-active=1');
            }

            if (query.onlyInactive && query.onlyInactive === true) {
                parameters.push('only-inactive=1');
            }

            if (query.byReferenceId && query.byRefrenceValue) {
                parameters.push('by-ref-id=' + query.byReferenceId);
                parameters.push('by-ref-value=' + query.byRefrenceValue);
            }

            if (query.byFieldId && query.byFieldValue) {
                parameters.push('by-field-id=' + query.byFieldId);
                parameters.push('by-field-value=' + query.byFieldValue);
            }

            if (query.byCreditorId) {
                parameters.push('by-creditor-id=' + query.byCreditorId);
            }

            if (query.byOrderDebtorId) {
                parameters.push('by-order-debtor-id=' + query.byOrderDebtorId);
            }

            if (query.byParentRelation) {
                parameters.push('by-product-relations=' + query.byParentRelation);
            }

            if (query.withProductFields) {
                parameters.push('with-product-fields=1');
            }

            if (query.withProductRelations) {
                parameters.push('with-product-relations=1');
            }

            if (query.byCategories) {
                parameters.push('by-categories=' + query.byCategories);
            }

            if (query.withProductStocks) {
                parameters.push('with-product-stocks=1');
            }

            if (query.withAdjustedPrices) {
                parameters.push('with-adjusted-prices=1');
            }

            if (query.withAdjustedCostPrices) {
                parameters.push('with-adjusted-cost-prices=1');
            }

            if (query.withUnit) {
                parameters.push('with-unit=1');
            }

            if (query.withStatistics) {
                parameters.push('with-statistics=1');
            }

            if (query && query.statisticsYear) {
                parameters.push('statistics-year=' + query.statisticsYear);
            }

            if (query && query.statisticsMonth) {
                parameters.push('statistics-month=' + query.statisticsMonth);
            }

            if (query && query.withAttachments) {
                parameters.push('with-attachments=1');
            }

            if (query && query.fromOrderId) {
                parameters.push('fromOrderId=' + query.fromOrderId);
            }
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Product[]>(url, true, Product);
    }

    addProduct(product: Product): Observable<{ data: Product, count: number, perPage: number }> {
        return this.apiService.post<Product>(this.url, product, true, Product);
    }

    updateProduct(product: Product): Observable<{ data: Product, count: number, perPage: number }> {
        return this.apiService.patch<Product>(this.url + '/' + product.id, product, true, Product);
    }

    deleteProduct(product: Product): Observable<{ data: Product, count: number, perPage: number }> {
        return this.apiService.delete<Product>(this.url + '/' + product.id, true, Product);
    }

    parseFile(file: File): Observable<{ data: Empty }> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.apiService.post<Empty>(this.url + '/import/parse', formData, true, Empty, null);
    }

    processFile(file: File, productImportMappingColumns: ProductImportMappingColumn[], creditorId: string = null) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('mappings', JSON.stringify(productImportMappingColumns));

        if (creditorId) {
            formData.append('creditor_id', JSON.stringify(creditorId));
        }

        return this.apiService.post<Empty>(this.url + '/import/process', formData, true, Empty, null);
    }
}

export interface ProductsQueryInterface {
    byReferenceId?: string;
    byRefrenceValue?: any;
    byCategories?: string;
    byCreditorId?: string;
    byOrderDebtorId?: string;
    byFieldId?: string;
    byFieldValue?: string;
    byParentRelation?: string;
    forProductName?: string;
    forProductReference?: string;
    forProductReferenceValue?: string;
    withProductRelations?: boolean;
    withProductFields?: boolean;
    withProductStocks?: boolean;
    withProductQuantityDiscounts?: boolean;
    withAdjustedPrices?: boolean;
    withAdjustedCostPrices?: boolean;
    withAttachments?: boolean;
    withUnit?: boolean;
    withStatistics?: boolean;
    statisticsYear?: number;
    statisticsMonth?: number;
    onlyActive?: boolean;
    onlyInactive?: boolean;
    forCurrency?: string;
    fromOrderId?:string;
}
