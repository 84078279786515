import {Component, OnInit} from '@angular/core';
import {ProductsService} from '../services/products.service';
import {VatTypesService} from '../services/vat-types.service';
import {UnitsService} from '../services/units.service';
import {CreditorsService} from '../services/creditors.service';
import {PriceGroupsService} from '../services/price-groups.service';
import {ProductFieldsService} from '../services/product-fields.service';
import {ReferencesService} from '../services/references.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AttachmentsService} from '../services/attachments.service';
import {LanguagesService} from '../services/languages.service';
import {Creditor} from '../services/creditor';
import {VatType} from '../services/vat-type';
import {Unit} from '../services/unit';
import {PriceGroup} from '../services/price-group';
import {ProductField} from '../services/product-field';
import {Product} from '../services/product';
import {ProductsProductField} from '../services/products-product-field';
import {forkJoin} from 'rxjs';
import {Reference} from '../services/reference';
import {Translation} from "../services/translation";
import {LocaleTranslation} from "../services/locale-translation";

@Component({
    selector: 'app-product-editor',
    templateUrl: './product-editor.component.html',
    styleUrls: ['./product-editor.component.scss'],
    providers: [ProductsService, CreditorsService, PriceGroupsService, ReferencesService, ProductFieldsService, VatTypesService, UnitsService, AttachmentsService, LanguagesService]

})
export class ProductEditorComponent implements OnInit {

    productId;

    creditors: Creditor[];
    vatTypes: VatType[];
    units: Unit[];
    priceGroups: PriceGroup[];
    productFields: ProductField[];

    // tslint:disable-next-line:variable-name
    _product: Product;
    // tslint:disable-next-line:variable-name
    _products_product_fields: ProductsProductField[] = [];

    saving = false;
    loading = false;

    missingLocalizations;
    name = [];
    fieldTranslations = [];

    activeTab = 'product';

    constructor(public productsService: ProductsService,
                public vatTypesService: VatTypesService,
                public unitsService: UnitsService,
                public creditorsService: CreditorsService,
                public priceGroupsService: PriceGroupsService,
                public productFieldsService: ProductFieldsService,
                public referencesService: ReferencesService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router,
                public attachmentService: AttachmentsService,
                public languagesService: LanguagesService,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            /**
             * Unset product, it could be set already if we navigate to another product
             */
            this._product = null;

            this.changeTab('product');
            /**
             * Set product ID and trigger a load of content
             */
            this.productId = params.get('productId');
            this.languagesService.getLanguages().subscribe(() => {
                this.load(this.productId);
            });
        });

    }

    public load(productId: string): void {
        if (productId) {
            forkJoin([
                this.creditorsService.getCreditors(1, -1, false, false),
                this.vatTypesService.getVatTypes(),
                this.unitsService.getUnits(),
                this.referencesService.getReferences(),
                this.productFieldsService.getProductFields(productId, null),
                this.productsService.getProduct(productId, true, true, true, true, true, true, 'null', true, true, true, true, {
                    withProductQuantityDiscounts: true,
                    withAttachments: true
                })
            ]).subscribe(joinData => {
                const product = Product.fromJSON(joinData[5].data);

                this.creditors = joinData[0].data;
                this.vatTypes = joinData[1].data;
                this.units = joinData[2].data;

                this.productFields = joinData[4].data;

                this.applyProductFieldsToProduct(joinData[4].data, product);

                for (const reference of joinData[3].data) {
                    if (reference.is_product_reference && !product.hasReference(reference.id)) {
                        product.references.push(Reference.fromJSON({
                            id: reference.id,
                            name: reference.name,
                            _joinData: {
                                value: ''
                            }
                        }));
                    }
                }

                this._product = product;
                this.missingLocalizations = this._product._translations.checkMissingLocalizations(this.languagesService.languages);
                this.loadLanguages();
            });
        } else {
            forkJoin([
                this.creditorsService.getCreditors(1, -1, false, false),
                this.vatTypesService.getVatTypes(),
                this.unitsService.getUnits(),
                this.referencesService.getReferences()
            ]).subscribe(joinData => {
                const product = {
                    creditor_id: null,
                    vat_type_id: null,
                    unit_id: null,
                    references: []
                };

                this.creditors = joinData[0].data;
                this.vatTypes = joinData[1].data;
                this.units = joinData[2].data;

                for (const vatType of this.vatTypes) {
                    if (vatType.is_default) {
                        product.vat_type_id = vatType.id;
                    }
                }

                for (const unit of this.units) {
                    if (unit.is_default) {
                        product.unit_id = unit.id;
                    }
                }

                for (const reference of joinData[3].data) {
                    if (reference.is_product_reference) {
                        product.references.push(Reference.fromJSON({
                            id: reference.id,
                            name: reference.name,
                            _joinData: {
                                value: ''
                            }
                        }));
                    }
                }

                this._product = Product.fromJSON(product);
                this.missingLocalizations = null;
                this.loadLanguages();
            });
        }
    }

    loadLanguages(): void {
        this.reorderProductFields();
        let i = 0;
        let ii = 0;
        for (const productField of this._product.product_fields) {
            const tempField = new ProductsProductField();
            tempField.product_id = this._product.id;
            tempField.product_field_id = productField.id;
            this._products_product_fields[ii] = tempField;
            this.fieldTranslations[ii] = [];
            ii++;
        }
        ii = 0;
        // Fill out array with the values that we do have
        for (const productsProductField of this._product.products_product_fields) {
            if (productsProductField) {
                this._products_product_fields[ii] = productsProductField;
            }
            ii++;
        }

        this.languagesService.languages.forEach((language) => {
            for (const fieldTranslation of this.fieldTranslations) {
                fieldTranslation[i] = '';
            }
            if (language.locale === this.languagesService.primaryLanguage.locale) {
                if (this._product.name) {
                    this.name[i] = this._product.name;
                } else {
                    this.name[i] = '';
                }

                ii = 0;
                for (const productsProductField of this._product.products_product_fields) {
                    if (this.fieldTranslations[ii] === undefined) {
                        this.fieldTranslations[ii] = [];
                    }
                    if (productsProductField?.value) {
                        this.fieldTranslations[ii][i] = productsProductField.value;
                    } else {
                        this.fieldTranslations[ii][i] = '';
                    }
                    ii++;
                }
            } else {
                if (this._product._translations) {
                    const translation = this._product._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }

                ii = 0;
                for (const productsProductField of this._product.products_product_fields) {
                    if (this.fieldTranslations[ii] === undefined) {
                        this.fieldTranslations[ii] = [];
                    }

                    if (productsProductField?._translations) {
                        const translation = productsProductField._translations[language.locale];
                        if (translation) {
                            if (translation.value) {
                                this.fieldTranslations[ii][i] = translation.value;
                            } else {
                                this.fieldTranslations[ii][i] = '';
                            }
                        } else {
                            this.fieldTranslations[ii][i] = '';
                        }
                    } else {
                        this.fieldTranslations[ii][i] = '';
                    }
                    ii++;
                }
            }
            i++;
        });
    }

    reorderProductFields(): void {
        const newList: ProductsProductField[] = [];
        this._product.product_fields.forEach((productField) => {
            this._product.products_product_fields.forEach((productsProductField) => {
                if (productField.id === productsProductField.product_field_id) {
                    newList.push(productsProductField);
                }
            });
        });
        this._product.products_product_fields = newList;
    }

    protected applyProductFieldsToProduct(productFields: ProductField[], product: Product): void {

        for (const productField of productFields) {
            if (!product.hasProductField(productField.id)) {
                product.product_fields.push(ProductField.fromJSON({
                    id: productField.id,
                    name: productField.name,
                    type: productField.type,
                    is_localized: productField.is_localized,
                    _joinData: {
                        value: ''
                    }
                }));
            }
        }
    }

    changeTab(event): void {
        this.activeTab = event;
    }

    loadProduct(): void {
        this.load(this._product.id);
    }

    public save(): void {
        this.saving = true;
        // Save _products_product_fields back into _product
        this._product.products_product_fields = this._products_product_fields;
        this.saveLanguages();

        if (this.productId) {
            this.productsService.updateProduct(this._product).subscribe(response => {
                this.saving = false;

                // @todo dosnt seem to refresh when saving

                this.router.navigateByUrl('/products/' + response.data.id);
            }, error => {
                this.saving = false;

                console.log(error.error.error);

                alert($localize`Something went wrong, please try again`);
            });
        } else {
            this.productsService.addProduct(this._product).subscribe(response => {
                this.saving = false;

                this.router.navigateByUrl('/products/' + response.data.id);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        let ii = 0;
        const translations: Translation = new Translation();
        const fieldTranslations = [];
        for (const productField of this._product.product_fields) {
            fieldTranslations[ii] = [];
            ii++;
        }

        // Fill out products_product_fields translations
        i = 0;
        for (const productsProductField of this._product.products_product_fields) {
            const fieldTranslation = new Translation();
            ii = 0;
            this.languagesService.languages.forEach((language) => {
                if (this.languagesService.isSame(language, this.languagesService.primaryLanguage)) {
                    if (this._product.product_fields[i].is_localized) {
                        productsProductField.value = this.fieldTranslations[i][ii];
                    } else if (!productsProductField.value) {
                        productsProductField.value = '';
                    }
                } else {
                    const fieldObject = new LocaleTranslation();
                    if (this._product.product_fields[i].is_localized) {
                        fieldObject.value = this.fieldTranslations[i][ii];
                    }
                    fieldObject.locale = language.locale;
                    fieldTranslation[language.locale] = fieldObject;
                }
                ii++;
            });
            fieldTranslations[i] = fieldTranslation;
            i++;
        }
        let iii = 0;
        this._product.products_product_fields.forEach((productField) => {
            productField._translations = fieldTranslations[iii];
            iii++;
        });

        // Fill out product translations
        i = 0;
        this.languagesService.languages.forEach((language) => {
            if (this.languagesService.isSame(language, this.languagesService.primaryLanguage)) {
                this._product.name = this.name[i];
            } else {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            }
            this._product._translations = translations;
            i++;
        });
    }
}
